// ----------------------------------------------------------------------

export function flattenArray(list, key = 'children') {
  let children = [];

  const flatten = list?.map((item) => {
    if (item[key] && item[key].length) {
      children = [...children, ...item[key]];
    }
    return item;
  });

  return flatten?.concat(children.length ? flattenArray(children, key) : children);
}

export const flattenData = (data, parentId = null) => {
  let rows = [];
  data.forEach(item => {
    const { childrens, ...rest } = item;
    rows.push({ ...rest, parentId });
    if (childrens && childrens.length > 0) {
      rows = rows.concat(flattenData(childrens, item.id));
    }
  });
  return rows;
};
