import PropTypes from "prop-types";

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import { styled } from '@mui/system';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { NavBasicDesktop } from 'src/components/nav-section/nav-basic';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// API
import { useGetPageBySlug } from 'src/api/web';
import { useLocales } from 'src/locales';
// routes
import { paths } from 'src/routes/paths';
import { useParams, usePathname, useRouter } from 'src/routes/hook';
import { fDateTime } from "src/utils/format-time";
import { m } from 'framer-motion';

// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import { useGetNavigation } from "src/api/front-end/home";
import { MotionContainer } from 'src/components/animate';
import { bgBlur } from 'src/theme/css';

//
import { HEADER } from '../config-layout';
import NavMobile from './nav/mobile';
//
import { HeaderShadow, LanguagePopover } from '../_common';
import TextLoop from "./text-loop";
import { useNavData } from "./config-navigation";
import getVariant from "./get-variant";





// ----------------------------------------------------------------------


export default function Header({ siteSetting }) {
  const theme = useTheme();
  const pathname = usePathname();

  const isHome = pathname === '/';

  const params = useParams();
  const { t, currentLang } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const [fixed, setFixed] = useState(false);




  const dataNav = useNavData();

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const location = useLocation();


  const functionFixed = () => {

    if (window.scrollY >= 80) {
      setFixed(true);
    }
    else {
      setFixed(false);
    }

  }
  useEffect(() => {
    window.addEventListener("scroll", functionFixed);
  }, [location.pathname, params]);


  const { rows: navigation } = useGetNavigation(currentLang.value);

  return (

    <AppBar >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container maxWidth="lg" sx={{ height: 1, display: 'flex', alignItems: 'center', mt: 3 }} >
          {mdUp &&
            <Container maxWidth="lg" sx={{ display: "flex", }}>
              <Box sx={{ mr: 15, }}>
                <Logo fileURL={siteSetting?.logo} sx={{ width: 40, height: 40 }} />
              </Box>
              <Box flexGrow={1} />
              <NavBasicDesktop
                // sx={{ mt: 1 }}
                data={navigation[0]?.children || []}
                slotProps={{
                  rootItem: {
                    fontFamily: theme.typography.fontSecondaryFamily,
                  },
                }}
              />
              <Box sx={{ width: 20 }} />
              <LanguagePopover />
            </Container>
          }

          {!mdUp && (
            <Container sx={{ display: "flex" }}>
              <NavMobile data={navigation[0]?.children || []} siteSetting={siteSetting} />
              <Box flexGrow={1} />
              <Logo fileURL={siteSetting?.logo} sx={{ width: 40, height: 40 }} />
              <Box flexGrow={1} />
              <Link href={paths.login} sx={{ mt: 0.8 }}>
                <Iconify icon="mdi:user" sx={{ width: 26, height: 26 }} />
              </Link>
              <LanguagePopover />
            </Container>
          )
          }
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>


  );
}

Header.propTypes = {
  siteSetting: PropTypes.object,
};