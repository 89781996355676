import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, PermissionBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------

// Dashboard
export const IndexPage = lazy(() => import('src/pages/dashboard/app'));


// Land and Properties pages
// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
const ProvincesListPage = lazy(() => import('src/pages/dashboard/location/province/list'));
const ProvinceNewPage = lazy(() => import('src/pages/dashboard/location/province/new'));
const ProvinceEditPage = lazy(() => import('src/pages/dashboard/location/province/edit'));
const DistrictsListPage = lazy(() => import('src/pages/dashboard/location/district/list'));
const DistrictNewPage = lazy(() => import('src/pages/dashboard/location/district/new'));
const DistrictEditPage = lazy(() => import('src/pages/dashboard/location/district/edit'));
const CommunesListPage = lazy(() => import('src/pages/dashboard/location/commune/list'));
const CommuneNewPage = lazy(() => import('src/pages/dashboard/location/commune/new'));
const CommuneEditPage = lazy(() => import('src/pages/dashboard/location/commune/edit'));
const VillagesListPage = lazy(() => import('src/pages/dashboard/location/village/list'));

// ------------------------------------------------------------------------------------------------

// setting
// ------------------------------------------------------------------------------------------------

const WebsiteSettingPage = lazy(() => import('src/pages/dashboard/website/setting/setting'));
const MenuListPage = lazy(() => import('src/pages/dashboard/website/setting/menu'));


// ------------------------------------------------------------------------------------------------

// Website
// ------------------------------------------------------------------------------------------------

const BlocksListPage = lazy(() => import('src/pages/dashboard/website/blocks/list'));
const BlockCreatePage = lazy(() => import('src/pages/dashboard/website/blocks/create'));
const BlockEditPage = lazy(() => import('src/pages/dashboard/website/blocks/edit'));

const CategoriesListPage = lazy(() => import('src/pages/dashboard/website/categories/list'));
const CategoryCreatePage = lazy(() => import('src/pages/dashboard/website/categories/create'));
const CategoryEditPage = lazy(() => import('src/pages/dashboard/website/categories/edit'));

const PostsListPage = lazy(() => import('src/pages/dashboard/website/posts/list'));
const PostCreatePage = lazy(() => import('src/pages/dashboard/website/posts/new'));
const PostEditPage = lazy(() => import('src/pages/dashboard/website/posts/edit'));

const PagesListPage = lazy(() => import('src/pages/dashboard/website/pages/list'));
const PageCreatePage = lazy(() => import('src/pages/dashboard/website/pages/new'));
const PageEditPage = lazy(() => import('src/pages/dashboard/website/pages/edit'));



// ----------------------------------------------------------------
const MemberListPage = lazy(() => import('src/pages/dashboard/members/list'));
const MemberCreatePage = lazy(() => import('src/pages/dashboard/members/create'));
const MemberEditPage = lazy(() => import('src/pages/dashboard/members/edit'));

const ScheduleListPage = lazy(() => import('src/pages/dashboard/schedules/list'));
const ScheduleCreatePage = lazy(() => import('src/pages/dashboard/schedules/create'));
const ScheduleEditPage = lazy(() => import('src/pages/dashboard/schedules/edit'));

const PartnersListPage = lazy(() => import('src/pages/dashboard/partners/list'));
const PartnerCreatePage = lazy(() => import('src/pages/dashboard/partners/create'));
const PartnerEditPage = lazy(() => import('src/pages/dashboard/partners/edit'));
const SlidersListPage = lazy(() => import('src/pages/dashboard/website/sliders/list'));
const SliderCreatePage = lazy(() => import('src/pages/dashboard/website/sliders/create'));
const SliderEditPage = lazy(() => import('src/pages/dashboard/website/sliders/edit'));

// Peeple
// ------------------------------------------------------------------------------------------------
const UserListPage = lazy(() => import('src/pages/dashboard/people/user/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/people/user/create'));
const UserEditPage = lazy(() => import('src/pages/dashboard/people/user/edit'));
const PeopleTypeListPage = lazy(() => import('src/pages/dashboard/people/type/list'));
const PeopleTypeCreatePage = lazy(() => import('src/pages/dashboard/people/type/create'));
const PeopleTypeEditPage = lazy(() => import('src/pages/dashboard/people/type/edit'));
const DepartmentListPage = lazy(() => import('src/pages/dashboard/people/department/list'));
const DepartmentCreatePage = lazy(() => import('src/pages/dashboard/people/department/create'));
const DepartmentEditPage = lazy(() => import('src/pages/dashboard/people/department/edit'));
const PositionListPage = lazy(() => import('src/pages/dashboard/people/position/list'));
const PositionCreatePage = lazy(() => import('src/pages/dashboard/people/position/create'));
const PositionEditPage = lazy(() => import('src/pages/dashboard/people/position/edit'));
const RoleListPage = lazy(() => import('src/pages/dashboard/people/role/list'));
const RoleCreatePage = lazy(() => import('src/pages/dashboard/people/role/create'));
const RoleEditPage = lazy(() => import('src/pages/dashboard/people/role/edit'));

// Careers
// ------------------------------------------------------------------------------------------------
const CommunitiesListPage = lazy(() => import('src/pages/dashboard/communities/list'));
const CommunityCreatePage = lazy(() => import('src/pages/dashboard/communities/create'));
const CommunityEditPage = lazy(() => import('src/pages/dashboard/communities/edit'));

const DonorsListPage = lazy(() => import('src/pages/dashboard/donors/list'));
const DonorCreatePage = lazy(() => import('src/pages/dashboard/donors/create'));
const DonorEditPage = lazy(() => import('src/pages/dashboard/donors/edit'));
// ------------------------------------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <IndexPage />, index: true },
            // --------------------------------------------------------------------------------------------
            {
                path: 'posts',
                children: [
                    { element: <PermissionBasedGuard permission='view_post'><PostsListPage /></PermissionBasedGuard>, index: true },
                    { path: 'create', element: <PermissionBasedGuard permission='create_post'> <PostCreatePage /></PermissionBasedGuard> },
                    { path: ':id/edit', element: <PermissionBasedGuard permission='update_post'><PostEditPage /></PermissionBasedGuard> },
                ],
            },
            {
                path: 'pages',
                children: [
                    { element: <PermissionBasedGuard permission='view_page'><PagesListPage /></PermissionBasedGuard>, index: true },
                    { path: 'create', element: <PermissionBasedGuard permission='create_page'><PageCreatePage /></PermissionBasedGuard> },
                    { path: ':id/edit', element: <PermissionBasedGuard permission='update_page'><PageEditPage /></PermissionBasedGuard> },
                ],
            },
            {
                path: 'members',
                children: [
                    { element: <PermissionBasedGuard permission='view_member'><MemberListPage /></PermissionBasedGuard>, index: true },
                    { path: 'create', element: <PermissionBasedGuard permission='create_member'><MemberCreatePage /></PermissionBasedGuard> },
                    { path: ':id/edit', element: <PermissionBasedGuard permission='update_member'><MemberEditPage /></PermissionBasedGuard> },
                ],
            },


            // --------------------------------------------------------------------------------------------
            {
                path: 'donors',
                children: [
                    { element: <PermissionBasedGuard permission='view_donor'><DonorsListPage /></PermissionBasedGuard>, index: true },
                    { path: 'create', element: <PermissionBasedGuard permission='create_donor'><DonorCreatePage /></PermissionBasedGuard> },
                    { path: ':id/edit', element: <PermissionBasedGuard permission='update_donor'><DonorEditPage /></PermissionBasedGuard> },

                ],
            },
            // --------------------------------------------------------------------------------------------
            {
                path: 'partners',
                children: [
                    { element: <PermissionBasedGuard permission='view_partner'> <PartnersListPage /></PermissionBasedGuard>, index: true },
                    { path: 'create', element: <PermissionBasedGuard permission='create_partner'><PartnerCreatePage /></PermissionBasedGuard> },
                    { path: ':id/edit', element: <PermissionBasedGuard permission='update_partner'><PartnerEditPage /></PermissionBasedGuard> },

                ],
            },
            // --------------------------------------------------------------------------------------------
            {
                path: 'communities',
                children: [
                    { element: <PermissionBasedGuard permission='view_community'> <CommunitiesListPage /></PermissionBasedGuard>, index: true },
                    { path: 'create', element: <PermissionBasedGuard permission='create_community'><CommunityCreatePage /></PermissionBasedGuard> },
                    { path: ':id/edit', element: <PermissionBasedGuard permission='update_community'><CommunityEditPage /></PermissionBasedGuard> },

                ],
            },
            // --------------------------------------------------------------------------------------------
            {
                path: 'website',
                children: [
                    { element: <PermissionBasedGuard permission='view_category'><CategoriesListPage /></PermissionBasedGuard>, index: true },
                    { path: 'create', element: <PermissionBasedGuard permission='create_category'><CategoryCreatePage /></PermissionBasedGuard> },
                    { path: ':id/edit', element: <PermissionBasedGuard permission='update_category'><CategoryEditPage /></PermissionBasedGuard> },

                    { path: 'blocks', element: <PermissionBasedGuard permission='view_block'><BlocksListPage /></PermissionBasedGuard> },
                    { path: 'blocks/create', element: <PermissionBasedGuard permission='create_block'><BlockCreatePage /></PermissionBasedGuard> },
                    { path: 'blocks/:id/edit', element: <PermissionBasedGuard permission='update_block'><BlockEditPage /></PermissionBasedGuard> },

                    { path: 'sliders', element: <PermissionBasedGuard permission='view_slider'><SlidersListPage /> </PermissionBasedGuard> },
                    { path: 'sliders/create', element: <PermissionBasedGuard permission='create_slider'><SliderCreatePage /></PermissionBasedGuard> },
                    { path: 'sliders/:id/edit', element: <PermissionBasedGuard permission='update_slider'><SliderEditPage /></PermissionBasedGuard> },
                ],
            },
            // --------------------------------------------------------------------------------------------

            {
                path: 'locations',
                children: [
                    { element: <PermissionBasedGuard permission='view_province'><ProvincesListPage /></PermissionBasedGuard>, index: true },
                    { path: 'create', element: <PermissionBasedGuard permission='create_province'><ProvinceNewPage /></PermissionBasedGuard> },
                    { path: ':id/edit', element: <PermissionBasedGuard permission='update_province'> <ProvinceEditPage /></PermissionBasedGuard> },
                    { path: 'districts', element: <PermissionBasedGuard permission='view_district'><DistrictsListPage /></PermissionBasedGuard> },
                    { path: 'districts/create', element: <PermissionBasedGuard permission='create_district'><DistrictNewPage /> </PermissionBasedGuard> },
                    { path: 'districts/:id/edit', element: <PermissionBasedGuard permission='update_district'><DistrictEditPage /></PermissionBasedGuard> },
                    { path: 'communes', element: <PermissionBasedGuard permission='view_commune'><CommunesListPage /></PermissionBasedGuard> },
                    { path: 'communes/create', element: <PermissionBasedGuard permission='create_commune'><CommuneNewPage /></PermissionBasedGuard> },
                    { path: 'communes/:id/edit', element: <PermissionBasedGuard permission='update_commune'><CommuneEditPage /></PermissionBasedGuard> },
                    { path: 'villages', element: <PermissionBasedGuard permission='view_village'><VillagesListPage /> </PermissionBasedGuard> },
                ]
            },

            {
                path: 'settings',
                children: [
                    {
                        path: 'general',
                        children: [
                            { element: <PermissionBasedGuard permission='view_setting'><WebsiteSettingPage /></PermissionBasedGuard>, index: true },

                            { path: 'menus', element: <PermissionBasedGuard permission='view_menu'><MenuListPage /></PermissionBasedGuard> },
                            { path: 'menus/create', element: <PermissionBasedGuard permission='create_menu'> <MenuListPage /></PermissionBasedGuard> },
                            { path: 'menus/:id/edit', element: <PermissionBasedGuard permission='update_menu'><MenuListPage /></PermissionBasedGuard> },

                            // { path: 'career-types', element: <CareerTypesListPage /> },
                            // { path: 'career-types/create', element: <CareerTypeCreatePage /> },
                            // { path: 'career-types/:id/edit', element: <CareerTypeEditPage /> },
                        ],
                    },

                    {
                        path: 'users',
                        children: [

                            { element: <PermissionBasedGuard permission='view_user'><UserListPage /></PermissionBasedGuard>, index: true },
                            { path: 'create', element: <PermissionBasedGuard permission='create_user'><UserCreatePage /></PermissionBasedGuard> },
                            { path: ':id/edit', element: <PermissionBasedGuard permission='update_user'><UserEditPage /></PermissionBasedGuard> },

                            // { element: <PeopleTypeListPage />, index: true },
                            // { path: 'create', element: <PeopleTypeCreatePage /> },
                            // { path: ':id/edit', element: <PeopleTypeEditPage /> },
                            // { path: 'departments', element: <DepartmentListPage /> },
                            // { path: 'departments/create', element: <DepartmentCreatePage /> },
                            // { path: 'departments/:id/edit', element: <DepartmentEditPage /> },
                            { path: 'positions', element: <PermissionBasedGuard permission='view_permission'><PositionListPage /></PermissionBasedGuard> },
                            { path: 'positions/create', element: <PermissionBasedGuard permission='create_permission'><PositionCreatePage /></PermissionBasedGuard> },
                            { path: 'positions/:id/edit', element: <PermissionBasedGuard permission='update_permission'><PositionEditPage /></PermissionBasedGuard> },

                            { path: 'roles', element: <PermissionBasedGuard permission='view_role'><RoleListPage /></PermissionBasedGuard>, index: true },
                            { path: 'roles/create', element: <PermissionBasedGuard permission='create_role'><RoleCreatePage /></PermissionBasedGuard> },
                            { path: 'roles/:id/edit', element: <PermissionBasedGuard permission='update_role'><RoleEditPage /></PermissionBasedGuard> },
                        ],
                    },

                    {
                        path: 'website',
                        children: [
                            { element: <WebsiteSettingPage />, index: true },
                            { path: 'menu', element: <MenuListPage /> },
                        ]
                    },
                ]
            },
            // --------------------------------------------------------------------------------------------
        ],
    },
];
